exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-other-js": () => import("./../../../src/pages/categories/other.js" /* webpackChunkName: "component---src-pages-categories-other-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-brands-js": () => import("./../../../src/templates/brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-merchants-js": () => import("./../../../src/templates/merchants.js" /* webpackChunkName: "component---src-templates-merchants-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-list-by-brand-js": () => import("./../../../src/templates/productListByBrand.js" /* webpackChunkName: "component---src-templates-product-list-by-brand-js" */),
  "component---src-templates-product-list-by-category-js": () => import("./../../../src/templates/productListByCategory.js" /* webpackChunkName: "component---src-templates-product-list-by-category-js" */),
  "component---src-templates-product-list-by-merchant-js": () => import("./../../../src/templates/productListByMerchant.js" /* webpackChunkName: "component---src-templates-product-list-by-merchant-js" */)
}

